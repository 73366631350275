<template>
  <div class="container">
    <div class="header_title">
      学生核心素养{{ Info.name ? "-" + Info.name : "" }}
    </div>
    <div class="info flexSb">
      <div class="info_detail">
        <div class="box2_title flexSb">
          <div>学生详情</div>
        </div>
        <div class="info_box flexSb">
          <div class="flexSt tae_c_a">
            <img class="img_tea" src="@/assets/img/201.png" alt="" />
            <!-- <img class="img_tea" src="@/assets/img/216.png" alt="" /> -->
            <div class="flexSb tae_c">
              <div style="width: 49.5%">
                <div class="flexSt info_list">
                  <div class="info_title info_title2">姓名：</div>
                  <div class="info_cont">
                    {{ Info.name ? Info.name : "- -" }}
                  </div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title info_title2">性别：</div>
                  <div v-if="Info.gender">
                    <div class="info_cont">
                      {{ Info.gender == 0 ? "女" : "男" }}
                    </div>
                  </div>
                  <div v-else>- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title info_title2">年龄：</div>
                  <div class="info_cont">{{ Info.age ? Info.age : "- -" }}</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title info_title2">年级：</div>
                  <div class="info_cont">
                    {{ Info.grade_name ? Info.grade_name : "- -" }}
                  </div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title info_title2">班级：</div>
                  <div class="info_cont">
                    {{ Info.class_name ? Info.class_name : "- -" }}
                  </div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title info_title2">所属学校：</div>
                  <div class="info_cont">
                    {{ Info.class_name ? Info.class_name : "- -" }}
                  </div>
                </div>
              </div>
              <div style="width: 49.5%">
                <div class="flexSt info_list">
                  <div class="info_title">体育教师：</div>
                  <div class="info_cont">
                    {{ Info.teacher_name ? Info.teacher_name : "- -" }}
                  </div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">体育特长：</div>
                  <div class="info_cont">- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">体育后备人才：</div>
                  <div class="info_cont">- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">运动员等级：</div>
                  <div class="info_cont">- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">获奖次数：</div>
                  <div class="info_cont">
                    <a href="">- -</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <img class="yxjs_img" src="@/assets/img/198.png" alt="" />
            <div class="yxjs">后备人才</div>
            <img class="yxjs_img" src="@/assets/img/204.png" alt="" />
            <div class="jly yxjs">三级运动员</div>
          </div>
        </div>
      </div>
      <div class="info_evaluate">
        <div class="box2_title">综合评价</div>
        <div class="evaluValue">
          <div>
            {{ InfoOverview.score ? InfoOverview.score : "- -" }}
            <span>分</span>
          </div>
          <div>{{ InfoOverview.type ? InfoOverview.type : "- -" }}</div>
        </div>
        <div class="evaluation" ref="evaluation_student"></div>
      </div>
    </div>

    <div class="coreIndicators">
      <div class="box2_title">核心指标</div>
      <div class="flexSb header">
        <div
          @click="handleTo('/studentAttendanceRecords')"
          class="header_box1 flexSb posi"
        >
          <div class="num_box">
            <div class="num">
              --
              <!-- <span>%</span> -->
            </div>
            <div class="num_title">体育课出勤率</div>
          </div>
          <img class="header_img" src="@/assets/img/194.png" alt="" />
        </div>
        <div
          @click="handleTo('/studentAttRecordsbreaktime')"
          class="header_box1 header_box2 flexSb posi"
        >
          <div class="num_box">
            <div class="num">
              --
              <!-- <span>%</span> -->
            </div>
            <div class="num_title">大课间出勤率</div>
          </div>
          <img class="header_img" src="@/assets/img/200.png" alt="" />
        </div>
        <div
          @click="handleTo('/homeWork')"
          class="header_box1 header_box3 flexSb posi"
        >
          <div class="num_box">
            <div class="num">
              --
              <!-- <span>%</span> -->
            </div>
            <div class="num_title">体育运动作业完成率</div>
          </div>
          <img class="header_img" src="@/assets/img/196.png" alt="" />
        </div>
        <div
          @click="handleTo('/homeWork')"
          class="header_box1 header_box4 flexSb posi"
        >
          <div class="num_box">
            <div class="num">
              --
              <!-- <span>%</span> -->
            </div>
            <div class="num_title">知识素养作业完成率</div>
          </div>
          <img class="header_img" src="@/assets/img/195.png" alt="" />
        </div>
      </div>
    </div>

    <div class="overview flexSb">
      <div class="overview_left">
        <div class="box2_title">分层概览</div>
        <div class="flexSt left_item_o">
          <div class="evaluValue_o evaluValue_o2">
            <div>
              {{ InfoScoreType.type ? InfoScoreType.type : "- -" }}
              <span>分</span>
            </div>
            <div>{{ InfoScoreType.type ? InfoScoreType.type : "- -" }}</div>
          </div>
          <div class="table_left">
            <div class="flexSt">
              <div class="tab_item_title flexSc">优秀</div>
              <div
                class="tab_item_cont flexSc"
                v-for="(item, index) in excellentlist"
                :key="index"
              >
                {{ excellent[index] ? excellent[index] : "" }}
              </div>
            </div>
            <div class="flexSt">
              <div class="tab_item_title tab_item_title2 flexSc">良好</div>
              <div
                class="tab_item_cont tab_item_cont2 flexSc"
                v-for="(item, index) in excellentlist"
                :key="index"
              >
                {{ good[index] ? good[index] : "" }}
              </div>
            </div>

            <div class="flexSt">
              <div class="tab_item_title tab_item_title3 flexSc">及格</div>
              <div
                class="tab_item_cont tab_item_cont3 flexSc"
                v-for="(item, index) in excellentlist"
                :key="index"
              >
                {{ pass[index] ? pass[index] : "" }}
              </div>
            </div>

            <div class="flexSt">
              <div class="tab_item_title tab_item_title4 flexSc">不及格</div>
              <div
                class="tab_item_cont tab_item_cont4 flexSc"
                v-for="(item, index) in excellentlist"
                :key="index"
              >
                {{ fail[index] ? fail[index] : "" }}
              </div>
            </div>

            <div class="flexSt">
              <div class="tab_item_title tab_item_title5 flexSc">优秀边缘</div>
              <div
                class="tab_item_cont tab_item_cont5 flexSc"
                v-for="(item, index) in excellentlist"
                :key="index"
              >
                {{ excellent_edge[index] ? excellent_edge[index] : "" }}
              </div>
            </div>
            <div class="flexSt">
              <div class="tab_item_title tab_item_title6 flexSc">及格边缘</div>
              <div
                class="tab_item_cont tab_item_cont6 flexSc"
                v-for="(item, index) in excellentlist"
                :key="index"
              >
                {{ pass_edge[index] ? pass_edge[index] : "" }}
              </div>
            </div>
          </div>
        </div>
        <div class="flexSt recommendation_box">
          <div class="recommendation flexSc">分层建议</div>
          <div class="cont">
            {{ InfoScoreType.report ? InfoScoreType.report : "无" }}
          </div>
        </div>
      </div>

      <div class="physical">
        <div class="box2_title">身体素质雷达图</div>
        <div class="evaluValue">
          <div>
            {{ RadarChartInfo.score ? RadarChartInfo.score : "- -" }}
            <span>分</span>
          </div>
          <div>{{ RadarChartInfo.type ? RadarChartInfo.type : "- -" }}</div>
        </div>
        <el-empty
          v-if="RadarChartInfo.sports_type_name.length == 0"
          description="暂无数据"
        ></el-empty>
        <div
          v-show="RadarChartInfo.sports_type_name.length > 0"
          class="evaluation"
          ref="physical"
        ></div>
      </div>
    </div>

    <div class="national_health">
      <div class="box2_title">国家体质健康</div>
      <div class="flexSb">
        <div class="nati_left flexSc">
          <div>
            <div>
              总分：
              <span>
                {{
                  PhysicalHealthInfo.score ? PhysicalHealthInfo.score : "- -"
                }}分</span
              >
            </div>
            <div>
              等级：
              <span>
                {{ PhysicalHealthInfo.type ? PhysicalHealthInfo.type : "- -" }}
              </span>
            </div>
          </div>
        </div>

        <div class="national_health_a" ref="national_health"></div>
      </div>
    </div>
    <div class="national_health">
      <div class="box2_title">身体形态</div>
      <div class="flexSb">
        <div class="nati_left nati_left2 flexSc">
          <div>
            <div>总分： <span> --分</span></div>
            <div>等级： <span> - - </span></div>
          </div>
        </div>
        <div class="national_health_a" ref="national_body"></div>
      </div>
    </div>

    <div class="overview flexSb">
      <div class="overview_left">
        <div class="box2_title">体育知识</div>
        <div class="flexSb">
          <div class="nati_left nati_left3 flexSc">
            <div>
              <div>总分： <span> --分</span></div>
              <div>等级： <span> - - </span></div>
            </div>
          </div>
          <div class="national_health_a" ref="national_sports"></div>
        </div>
      </div>

      <div class="physical">
        <div class="box2_title">体育知识作业完成情况</div>
        <div class="flexSb">
          <div class="box_sorce" ref="box_sorce"></div>
          <div class="box_sorce" ref="box_completed"></div>
        </div>
      </div>
    </div>

    <div class="overview flexSb">
      <div class="overview_left">
        <div class="box2_title">运动行为</div>
        <div class="flexSb">
          <div class="nati_left nati_left4 flexSc">
            <div>
              <div>总分： <span> --分</span></div>
              <div>等级： <span> - - </span></div>
            </div>
          </div>
          <div class="national_health_a" ref="national_sports_behavior"></div>
        </div>
      </div>

      <div class="physical">
        <div class="box2_title">体育运动作业完成情况</div>
        <div class="flexSb">
          <div class="box_sorce" ref="box_sorce_behavior"></div>
          <div class="box_sorce" ref="box_completed_behavior"></div>
        </div>
      </div>
    </div>

    <div class="overview flexSb">
      <div class="overview_left">
        <div class="box2_title">体艺2+1</div>
        <div class="over_box">
          <div class="flexSt">
            <div class="evaluValue">
              <div>
                --
                <span>分</span>
              </div>
              <div>- -</div>
            </div>
            <div class="flexSb typelist">
              <div
                @click="handleChangeType(item)"
                :class="{ active: item.id == currentType }"
                class="type"
                v-for="(item, index) in typelist"
                :key="index"
              >
                {{ item.title }}

                <img
                  v-if="item.id == currentType"
                  class="a"
                  src="@/assets//img/156.png"
                  alt=""
                />
                <div v-if="item.id == currentType" class="a bbb"></div>

                <!-- <div v-if="item.id == currentType" class="sjx"></div> -->
              </div>
            </div>
          </div>

          <div class="type_box flexSb">
            <img class="jx" src="@/assets//img/205.png" alt="" />
            <div>
              <div class="num_a">
                - -
                <!-- <span>分</span> -->
              </div>
              <div class="num_title">分数</div>
            </div>
            <div>
              <div class="num_a">- -</div>
              <div class="num_title">等级</div>
            </div>
            <div>
              <div class="num_a">- -</div>
              <div class="num_title">认定时间</div>
            </div>
          </div>
        </div>
      </div>

      <div class="physical">
        <div class="box2_title">赛事参与</div>
        <div class="flexSb phy_box_box">
          <div class="phy_box">
            <div class="phy_num">- -</div>
            <div class="phy_title">国家级奖项</div>
          </div>
          <div class="phy_box phy_box1">
            <div class="phy_num">- -</div>
            <div class="phy_title">省级奖项</div>
          </div>
          <div class="phy_box phy_box2">
            <div class="phy_num">- -</div>
            <div class="phy_title">市级奖项</div>
          </div>
          <div class="phy_box phy_box3">
            <div class="phy_num">- -</div>
            <div class="phy_title">区级奖项</div>
          </div>
        </div>

        <div class="tab">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            max-height="230"
          >
            <el-table-column
              prop="name"
              label="赛事名称"
              width="260"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="type" label="赛事类型" align="center">
            </el-table-column>
            <el-table-column prop="zb" label="组别" align="center">
            </el-table-column>
            <el-table-column prop="cj" label="成绩" align="center">
            </el-table-column>
            <el-table-column
              fixed="right"
              prop="pm"
              label="排名"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {
  evaluation_student,
  physical,
  national_health,
  national_body,
  national_sports,
  box_sorce,
  box_completed,
  box_completed_behavior,
  box_sorce_behavior,
  national_sports_behavior,
} from "../enums/studentLiteracy";
import {
  studentInfo,
  studentInfoOverview,
  studentInfoScoreType,
  studentInfoRadarChart,
  studentInfoPhysicalHealth,
  homeworkInfo,
} from "@/api/evaluation";
export default {
  data() {
    return {
      PhysicalHealthInfo: {
        score: 0,
        type: 0,
      },
      InfoOverview: {
        score: 0,
        type: 0,
      },
      InfoScoreType: {
        score: 0,
        type: 0,
      },
      Info: {
        name: "",
        grade_name: "",
        gender: "",
        age: "",
        class_name: "",
        teacher_name: "",
      },
      ID: "",
      options: [],
      value: "",
      tableData: [],
      currentType: 1,
      typelist: [
        {
          id: "1",
          title: "篮球",
        },
        {
          id: "2",
          title: "足球",
        },
        {
          id: "3",
          title: "羽毛球",
        },
      ],
      box_completed_behavior: box_completed_behavior,
      box_sorce_behavior: box_sorce_behavior,
      national_sports_behavior: national_sports_behavior,

      box_completed: box_completed,

      box_sorce: box_sorce,
      evaluation_student: evaluation_student,
      national_body: national_body,
      physical: physical,
      national_health: national_health,
      national_sports: national_sports,
      excellentlist: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      excellent: [],
      // good: ["腰腹肌核心力量", "耐力-心肺功能"],
      // pass: ["柔韧性", "爆发-协调能力", "上肢力量"],
      // fail: ["速度-反应"],
      // edge_excellent: ["柔韧性"],
      // fail_excellent: ["上肢力量"],
      good: [],
      pass: [],
      fail: [],
      excellent_edge: [],
      pass_edge: [],
      RadarChartInfo: {
        score: 0,
        type: 0,
        sports_type_name: [],
      },
    };
  },
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.ID = this.$route.query.id;
    this.$nextTick(() => {
      window.scrollTo(0, 0);

      this.handleGetstudentInfo();
      this.handleGetstudentInfoOverview();
      this.handleGetstudentInfoScoreType();
      this.handleGetStudentInfoRadarChart();
      this.handleGetstudentInfoPhysicalHealth();
      this.handleGetstudentCoreCompetenciesHomeworkInfo(1);
      this.handleGetstudentCoreCompetenciesHomeworkInfo(2);

      this.handleInitnational_body();
      this.handleInitnational_sports();
      this.handleInitbox_sorce();
      this.handleInitbox_sorce_behavior();
      this.handleInitnational_sports_behavior();

      // 对接接口后删除
      // this.handleInitoverall_knowledge();
      // this.handleInitnational_health();
    });
  },
  methods: {
    async handleGetstudentCoreCompetenciesHomeworkInfo(type) {
      let data = {
        homework_type: type,
        id: this.ID,
      };
      // 1 = 家庭作业（体育运动）， 2 = 家庭作业（知识素养）
      await homeworkInfo(data).then((res) => {
        // 体育知识作业完成情况
        if (type == 2) {
          let bar_chart = res.data.bar_chart;
          let arr = bar_chart.arr;
          let year = bar_chart.year;
          this.box_completed.dataset.source = [];

          let yearlist = ["product", ...year];
          let arrlist = ["完成率", ...arr];
          this.box_completed.dataset.source = [yearlist, arrlist];

          this.handleInitbox_completed();
        } else if (type == 1) {
          // 体育运动作业完成情况
          let bar_chart = res.data.bar_chart;
          let arr = bar_chart.arr;
          let year = bar_chart.year;
          this.box_completed_behavior.dataset.source = [];

          let yearlist = ["product", ...year];
          let arrlist = ["完成率", ...arr];
          this.box_completed_behavior.dataset.source = [yearlist, arrlist];

          this.handleInitbox_completed_behavior();
        }
      });
    },
    async handleGetstudentInfoPhysicalHealth() {
      let data = {
        id: this.ID,
      };
      await studentInfoPhysicalHealth(data).then((res) => {
        let val = res.data;

        let yearlist = ["product", ...val.year];
        let datalist = [];
        val.sports_type_name.forEach((item, index) => {
          datalist.push([item, ...val.score_arr[index]]);
        });
        this.PhysicalHealthInfo = res.data;
        this.national_health.dataset.source = [];
        this.national_health.dataset.source.push(yearlist, ...datalist);
        this.handleInitnational_health();
        console.log(datalist, "datalist ");
        console.log(this.national_health, " this.national_health");
      });
    },
    async handleGetStudentInfoRadarChart() {
      let data = {
        id: this.ID,
      };
      this.physical.series[0].data = [];
      this.physical.radar.indicator = [];
      await studentInfoRadarChart(data).then((res) => {
        let val = res.data;
        this.RadarChartInfo = res.data;
        console.log(this.RadarChartInfo, "=RadarChartInfo");
        val.sports_type_name.forEach((item, index) => {
          this.physical.radar.indicator.push({
            name: item,
            max: 120,
          });

          this.physical.series[0].data.push({
            value: val.score_arr[index],
            name: item,

            label: {
              normal: {
                show: true,
                color: this.physical.color[index],
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: this.physical.color[index],
                  offset: 0,
                },
                {
                  color: this.physical.color[index],
                  offset: 1,
                },
              ]),
            },
          });
        });

        if (val.sports_type_name.length > 0) {
          this.$nextTick(() => {
            this.handleInitphysical();
          });
        }
      });
    },
    async handleGetstudentInfoScoreType() {
      let data = {
        id: this.ID,
      };
      await studentInfoScoreType(data).then((res) => {
        this.InfoScoreType = res.data;
        let score_distributed = res.data.score_distributed;

        this.excellent = score_distributed.excellent;
        this.good = score_distributed.good;
        this.pass = score_distributed.pass;
        this.fail = score_distributed.fail;
        this.excellent_edge = score_distributed.excellent_edge;
        this.pass_edge = score_distributed.pass_edge;
      });
    },
    async handleGetstudentInfo() {
      let data = {
        id: this.ID,
      };
      await studentInfo(data).then((res) => {
        this.Info = res.data;
      });
    },

    async handleGetstudentInfoOverview() {
      let data = {
        id: this.ID,
      };
      await studentInfoOverview(data).then((res) => {
        this.InfoOverview = res.data;
        res.data.info.forEach((item, index) => {
          this.evaluation_student.series[0].data.push({
            value: item,
            name: index,

            label: {
              normal: {
                show: true,
                color: this.evaluation_student.color[index],
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: this.evaluation_student.color[index],
                  offset: 0,
                },
                {
                  color: this.evaluation_student.color[index],
                  offset: 1,
                },
              ]),
            },
          });
        });
        this.handleInitoverall_knowledge();
      });
    },
    handleTo(url) {
      this.$router.push(url);
    },
    handleChangeType(item) {
      this.currentType = item.id;
    },
    handleInitnational_sports_behavior() {
      let chartDom = this.$refs.national_sports_behavior;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.national_sports_behavior);
    },
    handleInitbox_sorce_behavior() {
      let chartDom = this.$refs.box_sorce_behavior;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.box_sorce_behavior);
    },
    handleInitbox_completed_behavior() {
      let chartDom = this.$refs.box_completed_behavior;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.box_completed_behavior);
    },

    handleInitbox_completed() {
      let chartDom = this.$refs.box_completed;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.box_completed);
    },
    handleInitbox_sorce() {
      let chartDom = this.$refs.box_sorce;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.box_sorce);
    },
    handleInitnational_sports() {
      let chartDom = this.$refs.national_sports;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.national_sports);
    },
    handleInitnational_body() {
      let chartDom = this.$refs.national_body;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.national_body);
    },
    handleInitnational_health() {
      let chartDom = this.$refs.national_health;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.national_health);
    },
    handleInitphysical() {
      let chartDom = this.$refs.physical;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.physical);
    },
    handleInitoverall_knowledge() {
      let chartDom = this.$refs.evaluation_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.evaluation_student);
    },
  },
};
</script>
<style lang="less" scoped>
body {
  overflow: hidden;
}
.container {
  min-height: 100%;
  overflow: scroll;

  padding: 20px;
  box-sizing: border-box;
}
.sports {
  width: 60%;
}
.national_health {
  margin-top: 16px;
  height: 400px;

  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
  .nati_left {
    background-color: rgba(23, 162, 184, 1);
    color: rgba(16, 16, 16, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    height: 348px;
    width: 158px;
    border-radius: 0 0 0 10px;
    span {
      font-size: 24px;
    }
  }
  .nati_left2 {
    background-color: #28a745;
  }
  .national_health_a {
    height: 347px;
    width: 93%;
  }
}
.overview {
  margin-top: 16px;
  .typelist {
    width: 90%;
    padding: 0 0 0 20px;
    box-sizing: border-box;
    .type {
      cursor: pointer;
      min-width: 76px;
      padding: 0 5px;
      box-sizing: border-box;
      height: 37px;
      line-height: 37px;
      border-radius: 10px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      position: relative;
      background-color: #cee2ff;

      .a {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 58px;
        height: 22px;
        // z-index: 999 !important;
        // border: 1px dashed #226bff;
      }
      .bbb {
        top: 70px;
        height: 2px;
        background-color: #fff;
      }
    }
    .active {
      background-color: rgba(34, 107, 255, 1);
    }
  }
  .type_box {
    width: 100%;
    height: 159px;
    margin-top: 12px;
    border-radius: 10px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    border: 2px dashed rgba(34, 107, 255, 1);
    padding: 20px;
    box-sizing: border-box;
    .jx {
      width: 160px;
      height: 120px;
    }
    .num_a {
      color: rgba(34, 107, 255, 1);
      font-size: 26px;
      font-weight: 600;
      span {
        font-size: 18px;
      }
    }
    .num_title {
      color: rgba(34, 107, 255, 1);

      font-size: 18px;
    }
  }
  .over_box {
    padding: 23px 20px;
    box-sizing: border-box;
    .evaluValue {
      width: 78px;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(23, 162, 184, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
  }

  .overview_left {
    width: 60%;
    height: 400px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);

    .left_item_o {
      padding: 14px 20px;
      box-sizing: border-box;
      align-items: flex-start;
      .table_left {
        margin-left: 17px;
        width: 90%;
        .tab_item_title {
          // width: 85px;
          width: 30%;
          height: 40px;
          background-color: rgba(25, 117, 209, 1);
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          text-align: center;
        }
        .tab_item_cont {
          width: 30%;
          height: 40px;
          background-color: rgba(72, 140, 209, 1);
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          text-align: center;
          border-left: 1px solid #fff;
        }
        .tab_item_title2 {
          background-color: rgba(2, 144, 22, 1);
        }
        .tab_item_cont2 {
          background-color: #2eac3f;
        }
        .tab_item_title3 {
          background-color: rgba(234, 165, 0, 1);
        }
        .tab_item_cont3 {
          background-color: rgba(242, 189, 66, 1);
        }
        .tab_item_title4 {
          background-color: rgba(228, 73, 69, 1);
        }
        .tab_item_cont4 {
          background-color: rgba(255, 99, 95, 1);
        }
        .tab_item_title5 {
          background-color: rgba(124, 37, 247, 1);
        }
        .tab_item_cont5 {
          background-color: rgba(154, 82, 255, 1);
        }
        .tab_item_title6 {
          background-color: rgba(34, 107, 255, 1);
        }
        .tab_item_cont6 {
          background-color: rgba(77, 136, 253, 1);
        }
      }
    }
    .evaluValue_o {
      // width: 78px;
      width: 78px;
      // width: 10%;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
    .evaluValue_o2 {
      background-color: rgba(23, 162, 184, 1);
    }
    .recommendation {
      width: 78px;
      height: 67px;
      border-radius: 10px;
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
    }
    .recommendation_box {
      padding: 0 20px;
      box-sizing: border-box;
      align-items: stretch;
      .cont {
        // flex: 1;
        border-radius: 10px;
        background-color: rgba(34, 107, 255, 1);
        color: rgba(16, 16, 16, 1);
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        padding: 9px 13px;
        box-sizing: border-box;
        width: 90%;
        margin-left: 17px;
      }
    }
  }
  .physical {
    width: 39%;
    height: 400px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
    position: relative;
    .evaluValue {
      position: absolute;
      top: 68px;
      left: 20px;
      width: 78px;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
    .evaluation {
      height: 324px;
      color: rgba(16, 16, 16, 1);
      font-size: 18px;
      text-align: center;
      padding-top: 20px;
      box-sizing: border-box;
    }
  }
  .nati_left {
    background-color: rgba(23, 162, 184, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    height: 348px;
    width: 158px;
    border-radius: 0 0 0 10px;
    span {
      font-size: 24px;
    }
  }
  .nati_left3 {
    background-color: #f2bd42;
  }
  .nati_left4 {
    background-color: #dc3545;
  }
  .national_health_a {
    height: 347px;
    width: 93%;
  }
  .box_sorce {
    width: 49.5%;
    height: 324px;
  }
}
.phy_box {
  width: 23%;
  // height: 60px;
  text-align: center;
  background-color: rgba(23, 162, 184, 1);
  border-radius: 10px;
  padding-bottom: 4px;
  box-sizing: border-box;
  .phy_num {
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
  }
  .phy_title {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
  }
}
.phy_box1 {
  background-color: rgba(40, 167, 69, 1);
}
.phy_box2 {
  background-color: rgba(255, 193, 7, 1);
}
.phy_box3 {
  background-color: rgba(220, 53, 69, 1);
}
.phy_box_box {
  margin: 21px 20px;
}
.tab {
  padding: 0 20px;
  box-sizing: border-box;
}
.coreIndicators {
  height: 280px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
  .header {
    margin-top: 28px;
    padding: 0 36px;

    box-sizing: border-box;

    .header_box1 {
      box-sizing: border-box;
      height: 138px;
      width: 23%;
      border-radius: 10px;
      background-color: rgba(23, 162, 184, 1);
      padding: 0 0 0 30px;
      box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
      position: relative;
      cursor: pointer;
      .num {
        color: rgba(255, 255, 255, 1);
        font-size: 35px;
        margin-bottom: 6px;
        padding-top: 23px;
        box-sizing: border-box;
      }
      .num_title {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        box-sizing: border-box;
        padding-bottom: 39px;
      }
      .header_img {
        width: 125px;
        height: 125px;
        position: absolute;
        right: 0;
      }
    }
    .header_box4 {
      background-color: #dc3545;
      .detail {
        background-color: rgba(198, 48, 62, 1);
      }
    }
    .header_box3 {
      background-color: #ffc107;
      .detail {
        background-color: rgba(229, 173, 6, 1);
      }
    }
    .header_box2 {
      background-color: #28a745;
      .detail {
        background-color: rgba(36, 150, 62, 1);
      }
    }
    .header_box5 {
      background-color: #7f63f2;
      .detail {
        background-color: #6644f3;
      }
    }
  }
}
.header_title {
  color: rgba(16, 16, 16, 1);
  font-size: 26px;
}
.box2_title {
  color: rgba(16, 16, 16, 1);
  font-size: 20px;
  width: 100%;
  height: 52px;
  line-height: 52px;
  padding: 0 22px;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.num_box {
  position: absolute;
  z-index: 999;
}
.info {
  margin-top: 22px;
  margin-bottom: 16px;
  align-items: stretch; //
  .info_detail {
    width: 70%;
    // height: 351px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);

    .info_box {
      padding: 20px 30px;
      align-items: flex-start;
      box-sizing: border-box;
      .yxjs_img {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        margin-bottom: 2px;
      }
      .yxjs {
        color: rgba(34, 107, 255, 1);
        font-size: 16px;
        text-align: center;
        margin-bottom: 12px;
      }
      .jly {
        color: rgba(242, 189, 66, 1);
        margin-bottom: 0;
      }
      .flexSt {
        flex-wrap: wrap;
        align-items: flex-start;
      }
      .img_tea {
        // width: 200px;
        // height: 258px;
        width: 20%;
        margin-right: 28px;
        border-radius: 10px;
      }
      .tae_c_a {
        width: 90%;
      }
      .tae_c {
        width: 65%;
        align-items: flex-start;
      }
      .info_list {
        margin-bottom: 18px;
        color: rgba(16, 16, 16, 1);
        font-size: 18px;
        align-items: flex-start;

        .info_cont {
          // width: 248px;
          overflow: auto;
          a {
            text-decoration: underline;
            color: rgba(16, 16, 16, 1);
          }
          a:hover {
            color: #226bff; /* 鼠标放上去的颜色 */
            cursor: pointer;
          }
        }
        .info_title {
          // width: 126px;
          font-weight: 600;
        }
        .info_title1 {
        }
      }
    }
  }
  .info_evaluate {
    width: 29%;
    flex: 1;
    margin-left: 1%;
    // height: 351px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    .evaluValue {
      position: absolute;
      top: 68px;
      left: 20px;
      width: 78px;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
    .evaluation {
      height: 257px;
      color: rgba(16, 16, 16, 1);
      font-size: 18px;
      text-align: center;
      padding-top: 20px;
      box-sizing: border-box;
    }
  }
}
/deep/.el-table .el-table__cell {
  padding: 8px 0 !important;
}
/deep/.cell {
  color: #202020 !important;
}
.tab {
  // ::-webkit-scrollbar {
  //   width: 10px !important;
  //   height: 10px !important;
  //   background-color: #ccc;
  // }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    // background-color: #ccc !important;
  }
  ::-webkit-scrollbar-track {
    // background-color: #bbbbbb;
  }
  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}
.sjx {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid skyblue;
}
</style>
